<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="regionData.avatar"
              :text="avatarText(`${regionData.prenom} ${regionData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(regionData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ regionData.nom }}
                </h4>
                <h4 v-if="!regionData.id">
                  Chargement en cours...
                </h4>
                <span class="card-text">{{ regionData.telephone }}</span>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="8"
          md="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageResponsable"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Responsable :</span>
              </th>
              <td class="pb-50">
                {{ regionData.responsable }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageCommerciaux"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Commerciaux :</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ regionData.nbr_commercial }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageDistributeurs"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Distributeurs : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ regionData.nbr_distributeur }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageTourners"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Tournées : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ regionData.nbr_tourner }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePDV"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de PDV : </span>
              </th>
              <td class="pb-50">
                {{ regionData.nbr_point_de_vente }}
              </td>
            </tr>
          </table>
        </b-col>
        <!--        <b-col-->
        <!--          cols="6"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <vue-apex-charts-->
        <!--            height="120"-->
        <!--            :options="earningsChart.chartOptions"-->
        <!--            :series="[70, 30]"-->
        <!--          />-->
        <!--          <h4 class="mb-0 text-center">-->
        <!--            Les Tournées du jour-->
        <!--          </h4>-->
        <!--        </b-col>-->
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Commerciaux</span>
          </template>
          <CommerciauxListeMainVue
            :rows="commerciaux"
            cols=""
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageDistributeurs"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Distributeurs</span>
          </template>
          <DistributeursListeMainVue :rows="distributeurs" />
        </b-tab>
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePDV"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">PDV</span>
          </template>
          <!--          <DistributeursListeMainVue :rows="distributeurs" />-->
          <PDVListeMainVue :pdvs="pdvs" />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import CommerciauxListeMainVue from '@/views/afridia/regions/details/CommerciauxListeMainVue.vue'
import DistributeursListeMainVue from '@/views/afridia/regions/details/DistributeursListeMainVue.vue'
import TournerListeMainVue from '@/views/afridia/tourners/liste/TournerListeMainVue.vue'
import { API_URL } from '@/helpers/global-scops'
import PDVListeMainVue from '@/views/afridia/regions/details/PDVListeMainVue.vue'

const $earningsStrokeColor2 = 'rgb(3,147,255)'
const $earningsStrokeColor3 = 'rgba(3,147,255,0.28)'

export default {
  name: 'MainVue',
  components: {
    PDVListeMainVue,
    TournerListeMainVue,
    DistributeursListeMainVue,
    CommerciauxListeMainVue,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      pdvs: [],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Tournées',
                    formatter() {
                      return '70%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      regionData: {
        id: null,
        nom: '',
        responsable: '',
        nombre_commerciaux: '',
        nombre_distributeurs: '',
        nombre_tournees: '',
        nombre_pdv: '',
      },
      commerciaux: [],
      distributeurs: [],
      // eslint-disable-next-line global-require
      imageDistributeurs: require('@/assets/images/icons/box.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/team.png'),
      // eslint-disable-next-line global-require
      imageResponsable: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageTourners: require('@/assets/images/icons/tourners.png'),
      // eslint-disable-next-line global-require
      imagePDV: require('@/assets/images/icons/store.png'),
      userData: {},
    }
  },
  created() {
    this.getRegionData()
    this.getCommerciaux()
    this.getDistributeurs()
    this.getPDV()
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  methods: {
    getRegionData() {
      this.$http.get(`${API_URL}regions/${this.$route.params.id}/`)
        .then(response => {
          this.regionData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCommerciaux() {
      this.$http.get(`${API_URL}commercials/?region=${this.$route.params.id}`)
        .then(response => {
          this.commerciaux = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDistributeurs() {
      this.$http.get(`${API_URL}distributeurs/?region=${this.$route.params.id}`)
        .then(response => {
          this.distributeurs = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPDV() {
      this.$http.get(`${API_URL}point_de_ventes/`)
        .then(response => {
          this.pdvs = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

  },
}
</script>

<style scoped>

</style>
